import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDoctorAuth } from "../../context/docauth";
import Layout from "../../components/Layout/Layout";
import DateTimePicker from "./DateTimePicker";
import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/AddSlots.css";

const AddSlotsForm = () => {
  const [doctor, setDoctor] = useDoctorAuth("");
  const [dateTime, setDateTime] = useState("");
  const [loading, setLoading] = useState(false);
  const doctorId = doctor?.doctor?._id;
  const [appointments, setAppointments] = useState([]);
  const [showAddSlots, setShowAddSlots] = useState(true);

  // show previous added slots
  useEffect(() => {
    // Check if doctorId is available
    if (!doctorId) {
      toast.error("Doctor ID not provided");
      return;
    }

    axios
      .get(`/api/v1/fetch/preSlots/${doctorId}`)
      .then((response) => {
        const { appointments } = response.data;
        setAppointments(appointments);
      })
      .catch((error) => {
        console.error("Error fetching doctor data:", error);
        toast.error("Error fetching doctor data");
      });
  }, [doctorId]);
  console.log(appointments);

  function convertToIST(dateTimeString) {
    const utcDateTime = new Date(dateTimeString);
    const istDateTime = new Date(
      utcDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDateTime.toLocaleString();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Convert to IST
      const selectedDateTime = new Date(dateTime);

      // Check if selected date and time is in the past
      if (selectedDateTime < new Date()) {
        toast.error("Selected date and time should be in the future.");
        return;
      }

      const formattedDateTime = selectedDateTime.toISOString(); // Convert to ISO format

      const response = await axios.post("/api/v1/fetch/addDocSlots", {
        doctorId: doctor?.doctor?._id,
        dateTime: formattedDateTime,
      });

      if (response.status === 201) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        toast.error("Failed to add appointment slot.");
      }
    } catch (error) {
      toast.error(error.response.data);
    } finally {
      setLoading(false);
    }
  };

  const handleDateTimeChange = (newDateTime) => {
    setDateTime(newDateTime);
  };

  return (
    <Layout title="Add Slot">
      <div className="add-slots-page">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="add-slots-nav-bar">
                <li
                  className={`add-slots-nav-item ${
                    showAddSlots ? "active" : ""
                  }`}
                >
                  <button
                    className="add-slots-nav-link"
                    onClick={() => setShowAddSlots(true)}
                  >
                    Add Slots
                  </button>
                </li>

                <li
                  className={`add-slots-nav-item ${
                    !showAddSlots ? "active" : ""
                  }`}
                >
                  <button
                    className="add-slots-nav-link"
                    onClick={() => setShowAddSlots(false)}
                  >
                    Previous Appointments
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="add-slots-container">
          {showAddSlots ? (
            // Add Slots section

            <div className="calender-section">
              <h2>Add Slots</h2>
              <div className="datetime-picker">
                <DateTimePicker
                  value={dateTime}
                  onChange={handleDateTimeChange}
                />
              </div>
              <button
                onClick={handleSubmit}
                className="btn btn-primary"
                disabled={loading}
                style={{ marginTop: "30px" }}
              >
                {loading ? "Adding..." : "Add Appointment"}
              </button>
            </div>
          ) : (
            // Previous Appointments section
            <div className="panel">
              <h2 style={{ color: "yellow" }}>Previous Appointments</h2>
              <div className="previous-appointments-table">
                <div className="card-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Start Date and Time</th>
                        <th>End Date And Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appointments.length === 0 ? (
                        <tr>
                          <td colSpan="3">No slots found</td>
                        </tr>
                      ) : (
                        appointments.map((appointment) => (
                          <tr key={appointment._id}>
                            <td>{convertToIST(appointment.dateTime)}</td>
                            <td>{convertToIST(appointment.endDateTime)}</td>
                            <td>
                              {appointment.isAvailable === true
                                ? "empty"
                                : "booked"}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AddSlotsForm;
