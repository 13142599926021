import React, { useState } from "react";
import Calendar from "react-calendar";
import "../../styles/DateTimePicker.css"; // Import custom CSS for styling
import "react-calendar/dist/Calendar.css";

const DateTimePicker = ({ value, onChange }) => {
  const [dateTime, setDateTime] = useState(value || new Date());

  const handleDateTimeChange = (newDateTime) => {
    setDateTime(newDateTime);
    onChange(newDateTime);
  };

  return (
    <div className="calender-panel">
      <div className="datetime-picker">
        <div className="digital-clock">
          <input
            type="time"
            value={dateTime.toTimeString().substring(0, 5)}
            onChange={(e) => {
              const newDateTime = new Date(dateTime);
              const [hours, minutes] = e.target.value.split(":");
              newDateTime.setHours(parseInt(hours));
              newDateTime.setMinutes(parseInt(minutes));
              handleDateTimeChange(newDateTime);
            }}
          />
        </div>
        <div className="calendar">
          <Calendar onChange={handleDateTimeChange} value={dateTime} />
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
