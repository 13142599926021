import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../components/Layout/Layout";
import "../../styles/PatientHomePage.css";
import { toast } from "react-toastify";
import { usePatientAuth } from "../../context/Patientauth";

const PatientHomePage = () => {
  const [PatientAuth, setPatientAuth] = usePatientAuth();
  const [allAppointments, setAllAppointments] = useState([]);
  const [showUpcoming, setShowUpcoming] = useState(true); // Define showUpcoming state
  const patientId = PatientAuth?.patient?._id;

  function convertToIST(dateTimeString) {
    const utcDateTime = new Date(dateTimeString);
    const istDateTime = new Date(
      utcDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDateTime.toLocaleString();
  }

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await axios.get(
          `/api/v1/fetch/booked/appointment/${patientId}`
        );
        console.log(response);
        // Assuming the response contains an array of appointments with fields: id, date, reason
        setAllAppointments(response.data.appointments);
      } catch (error) {
        toast.error("Error fetching appointments:", error);
      }
    };

    fetchAppointments();
  }, []);

  // Separate appointments into history and upcoming based on date
  const historyAppointments = allAppointments.filter((appointment) => {
    const currentDate = new Date();
    const appointmentDate = new Date(appointment.dateTime);
    return appointmentDate < currentDate;
  });

  const upcomingAppointments = allAppointments.filter((appointment) => {
    const currentDate = new Date();
    const appointmentDate = new Date(appointment.dateTime);
    return appointmentDate >= currentDate;
  });

  return (
    <Layout title="Patient Home">
      <div className="patient-home-page-container">
        <div className="row mb-4">
          <div className="col-md-12">
            <h1 className="text-uppercase font-weight-bold text-black mb-4">
              Dashboard
            </h1>
            <hr className="my-4" /> {/* Horizontal bar */}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="patient-nav-bar">
                <li
                  className={`patient-nav-item ${showUpcoming ? "active" : ""}`}
                >
                  <button
                    className="patient-nav-link"
                    onClick={() => setShowUpcoming(true)}
                  >
                    Upcoming Appointments:{" "}
                    {
                      allAppointments.filter(
                        (appointment) =>
                          new Date(appointment.dateTime) >= new Date()
                      ).length
                    }
                  </button>
                </li>

                <li
                  className={`patient-nav-item ${
                    !showUpcoming ? "active" : ""
                  }`}
                >
                  <button
                    className="patient-nav-link"
                    onClick={() => setShowUpcoming(false)}
                  >
                    History
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {showUpcoming ? (
              <div className="patient-home-page-upcoming-appointments">
                <h2>Upcoming Appointments </h2>
                <div className="panel">
                  {upcomingAppointments.length === 0 ? (
                    <div className="text-center">
                      <p className="text-white">No records found</p>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Start Date</th>
                            <th>Start Time</th>
                            <th>End Date</th>
                            <th>End Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {upcomingAppointments.map((appointment) => (
                            <tr key={appointment.id}>
                              <td>
                                {
                                  convertToIST(appointment.dateTime).split(
                                    ","
                                  )[0]
                                }
                              </td>
                              <td>
                                {
                                  convertToIST(appointment.dateTime).split(
                                    ","
                                  )[1]
                                }
                              </td>
                              <td>
                                {
                                  convertToIST(appointment.endDateTime).split(
                                    ","
                                  )[0]
                                }
                              </td>
                              <td>
                                {
                                  convertToIST(appointment.endDateTime).split(
                                    ","
                                  )[1]
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="patient-home-page-history-appointments">
                <h2>History of Appointments</h2>
                <div className="panel">
                  {historyAppointments.length === 0 ? (
                    <div className="text-center">
                      <p className="text-white">No records found</p>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Start Date</th>
                            <th>Start Time</th>
                            <th>End Date</th>
                            <th>End Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {historyAppointments.map((appointment) => (
                            <tr key={appointment.id}>
                              <td>
                                {
                                  convertToIST(appointment.dateTime).split(
                                    ","
                                  )[0]
                                }
                              </td>
                              <td>
                                {
                                  convertToIST(appointment.dateTime).split(
                                    ","
                                  )[1]
                                }
                              </td>
                              <td>
                                {
                                  convertToIST(appointment.endDateTime).split(
                                    ","
                                  )[0]
                                }
                              </td>
                              <td>
                                {
                                  convertToIST(appointment.endDateTime).split(
                                    ","
                                  )[1]
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PatientHomePage;
