import React, { useState } from "react";
import Layout from "./../../components/Layout/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RingLoader } from "react-spinners";
import "../../styles/AuthStyles.css";

const DoctorForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const generateAndSendOtp = async () => {
    try {
      setIsResetting(true);

      const res = await axios.post(`/api/v1/auth/forgotDocPassword`, {
        email: email,
      });
      console.log(res);
      if (res && res.data.success) {
        toast.success("OTP sent to your email");
        setIsOtpSent(true);
      } else {
        toast.error(res.data.message || "Failed to send OTP");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsResetting(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!isOtpSent || !otp || !newPassword) {
      toast.error(
        "Please fill all fields and enter the OTP sent to your email."
      );
      return;
    }

    try {
      setIsResetting(true);

      const res = await axios.post(`/api/v1/auth/changeDocPassword`, {
        email,
        otp,
        password: newPassword,
      });

      if (res && res.data.success) {
        toast.success(res.data.message);
        // Optionally, you can navigate the user to login page after successful password reset
      } else {
        toast.error(res.data.message || "Failed to change password");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <Layout title="Forgot Password">
      <div className="form-container">
        <form onSubmit={isOtpSent ? handlePasswordReset : generateAndSendOtp}>
          <h4 className="title">Forgot Password</h4>
          <div className="mb-3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Enter Your Email"
              required
              disabled={isOtpSent}
            />
          </div>
          {isOtpSent ? (
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="form-control"
                placeholder="Enter OTP"
                required
              />
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="form-control"
                placeholder="Enter New Password"
                required
              />
              <button
                type="submit"
                className="btn btn-primary rounded-pill"
                disabled={isResetting}
              >
                {isResetting ? (
                  <>
                    <RingLoader color="hsla(283, 87%, 48%, 1)" size={35} />
                    <span style={{ marginLeft: "5px" }}>
                      {" "}
                      Resetting Password...
                    </span>
                  </>
                ) : (
                  "Reset Password"
                )}
              </button>
            </div>
          ) : (
            <button
              type="button"
              onClick={generateAndSendOtp}
              className={`btn btn-primary rounded-pill ${
                isResetting ? "disabled" : ""
              }`}
              disabled={isResetting}
            >
              {isResetting ? (
                <>
                  <RingLoader color="hsla(283, 87%, 48%, 1)" size={35} />
                  <span style={{ marginLeft: "5px" }}> Sending OTP...</span>
                </>
              ) : (
                "Send OTP"
              )}
            </button>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default DoctorForgotPassword;
