import React from "react";
import Layout from "../components/Layout/Layout";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import "../styles/HomePage.css";

const HomePage = () => {
  return (
    <Layout>
      <Container>
        <Row className="mt-5">
          <Col>
            <h1 className="text-center" style={{ color: "#007bff" }}>
              Welcome To CureConnect!
            </h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-fluid"
                  src="/images/back1.png"
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-fluid"
                  src="/images/back2.png"
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-fluid"
                  src="/images/back5.png"
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-fluid"
                  src="/images/back6.png"
                  alt="Fourth slide"
                />
              </Carousel.Item>
              {/* Add more Carousel.Item for additional images */}
            </Carousel>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h2 className="text-center">
              Explore our Centres of Clinical Excellence
            </h2>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6} md={12}>
            <img
              src="/images/back3.jpg"
              alt="Clinical Excellence"
              className="img-fluid"
              style={{ borderRadius: "20px" }}
            />
          </Col>
          <Col lg={6} md={12} className="mt-md-0 mt-3">
            <Row>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Gynecologist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Physician</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Psychiatrist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Dermatologist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Cardiologist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              {/* Add more Col components for additional cards */}
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Allergist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Neurologist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Paediatrician</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Internal Medicine</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Endocrinologist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Colorectal Surgeon</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Hepatologist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>ENT Specialist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} className="mb-3">
                <Card className="hover-card">
                  <Card.Body>
                    <Card.Title>Urologist</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <section>
          <Row className="mt-5">
            <Col md={6} className="mt-md-0 mt-2">
              <h3 className="text-center">Reasons to Choose CureConnect</h3>
              <p
                style={{
                  textAlign: "justify",
                }}
              >
                CureConnect, an innovative healthcare platform, endeavors to
                revolutionize the medical landscape by leveraging advanced
                technologies. Its primary objective is to provide precise
                disease predictions, utilizing cutting-edge algorithms and
                comprehensive data analysis. By harnessing the power of
                artificial intelligence and machine learning, CureConnect aims
                to offer tailored doctor recommendations, ensuring that patients
                receive personalized care that aligns with their unique medical
                needs and preferences. Moreover, CureConnect simplifies the
                process of scheduling appointments through its user-friendly
                interface, facilitating seamless access to healthcare services.
                By streamlining appointment booking procedures, CureConnect aims
                to eliminate barriers to healthcare access, making it easier for
                individuals to seek timely medical assistance when needed. This
                streamlined approach not only enhances convenience for patients
                but also optimizes efficiency within healthcare systems,
                ultimately leading to improved patient outcomes and overall
                healthcare quality.
              </p>
            </Col>
            <Col md={6} className="mt-md-0 mt-2">
              <img
                src="/images/back4.png"
                alt="Reasons"
                className="img-fluid"
                style={{ borderRadius: "30px" }}
              />
            </Col>
          </Row>
        </section>

        <Row className="mt-5">
          <Col>
            <h2 className="text-center">What Is New Today?</h2>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4} className="mb-4">
            <div className="card h-100">
              <img
                src="/images/news1.png"
                className="card-img-top img-fluid"
                alt="News 1"
              />
              <div className="card-body">
                <h5 className="card-title" style={{ fontWeight: "700" }}>
                  Revolutionizing Healthcare: Introducing CureConnect App for
                  Seamless Patient Care
                </h5>
                <p className="card-text">
                  In a groundbreaking move, the highly anticipated CureConnect
                  App is set to launch, promising to transform the healthcare
                  landscape. This innovative platform aims to streamline
                  patient-doctor interactions, providing a seamless experience
                  for all involved. Stay tuned for the unveiling of this
                  game-changing app!
                </p>
                <NavLink
                  to="/news1"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#41886c",
                    color: "white",
                    border: "1px solid #41886c",
                  }}
                >
                  Read More
                </NavLink>
              </div>
            </div>
          </Col>
          <Col md={4} className="mb-4">
            <div className="card h-100">
              <img
                src="/images/news2.jpg"
                className="card-img-top img-fluid"
                alt="News 2"
              />
              <div className="card-body">
                <h5 className="card-title" style={{ fontWeight: "700" }}>
                  Expanding Horizons: More Doctors Joining CureConnect to
                  Enhance Patient Care
                </h5>
                <p className="card-text">
                  CureConnect announces the integration of additional doctors
                  into its platform, enhancing accessibility and diversity in
                  healthcare services. With more experts on board, patients can
                  expect even greater convenience and specialized care. Explore
                  the upcoming features and stay ahead in your healthcare
                  journey!
                </p>
                <NavLink
                  to="/news2"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#41886c",
                    color: "white",
                    border: "1px solid #41886c",
                  }}
                >
                  Read More
                </NavLink>
              </div>
            </div>
          </Col>
          <Col md={4} className="mb-4">
            <div className="card h-100">
              <img
                src="/images/news3.jpg"
                className="card-img-top img-fluid"
                alt="News 3"
              />
              <div className="card-body">
                <h5 className="card-title" style={{ fontWeight: "700" }}>
                  Celebrating Success: Happy Customers Share Their CureConnect
                  Experience
                </h5>
                <p className="card-text">
                  Amidst the buzz of innovation, CureConnect celebrates a string
                  of satisfied customers, sharing their positive experiences
                  with the platform. From streamlined appointments to
                  personalized care, users express delight in the seamless
                  healthcare journey facilitated by CureConnect. Join the ranks
                  of happy customers and discover the future of healthcare
                  convenience today!
                </p>
                <NavLink
                  to="/news3"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#41886c",
                    color: "white",
                    border: "1px solid #41886c",
                  }}
                >
                  Read More
                </NavLink>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h2 className="text-center">What Would You Like To Do Today?</h2>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title" style={{ fontWeight: "600" }}>
                  Predict Disease
                </h5>
                <p className="card-text">
                  Feeling Uncertain About Your Health? Let Symptoms Speak:
                  Predicting Diseases with Precision. Say goodbye to uncertainty
                  as advanced algorithms decode your symptoms, unveiling
                  potential health concerns before they escalate. Embrace
                  proactive healthcare with symptom-based prediction, guiding
                  you towards timely interventions and peace of mind on your
                  health journey
                </p>
                <NavLink
                  to="/patient-login"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#41886c",
                    color: "white",
                    border: "1px solid #41886c",
                  }}
                >
                  Predict Here!
                </NavLink>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title" style={{ fontWeight: "600" }}>
                  Schedule Appointment
                </h5>
                <p className="card-text">
                  Effortless Health Connections: Coming Soon - Find Your Doctor,
                  Book Appointments with Ease. Seamlessly browse, select, and
                  schedule appointments, all on a user-friendly platform.
                  Whether it's a routine check-up or specialized care, quality
                  healthcare is just a click away. Stay tuned as we bring you
                  hassle-free access to a world of medical expertise, right at
                  your fingertips.
                </p>
                <NavLink
                  to="/patient-login"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#41886c",
                    color: "white",
                    border: "1px solid #41886c",
                  }}
                >
                  Schedule Now!
                </NavLink>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <div className="mainhome">
              <div className="testimonials">
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial1"
                  defaultChecked
                />
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial2"
                />
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial3"
                />
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial4"
                />
                <input
                  type="radio"
                  name="testimonial"
                  id="input-testimonial5"
                />
                <div className="testimonials-inner">
                  <div className="testimonial">
                    <div className="testimonial-photo">
                      <div className="photo-background"></div>
                      <div className="photo-author"></div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        "I think it's great that you're using AI to connect
                        patients with doctors based on symptoms and
                        specialization. This is especially important because
                        many patients don't know which doctor to see or what
                        their specialization is. They may only be able to
                        describe their symptoms, such as stomach pain or
                        headache, and rely on the ticket counter staff to direct
                        them to the right doctor. This can lead to incorrect
                        referrals and a lengthy process. Your project addresses
                        this issue by providing a quick and accurate way to
                        diagnose and find the right specialist. I believe this
                        will reduce the burden on the healthcare sector and
                        promote better care, especially in today's busy world
                        where people are becoming more tech-savvy."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      Dr. Medam Bagang
                      <div className="subtitle">
                        Department of Paediatric{" "}
                        <div className="subtitle">
                          MKCG medical college and hospital,Berhampur
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial">
                    <div className="testimonial-photo">
                      <div className="photo-background"></div>
                      <div className="photo-author"></div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        "I'm impressed by your semester project, which addresses
                        a critical need for innovative technologies that benefit
                        current and future generations. The rapid adoption of
                        technologies like QR codes has transformed the way we
                        live and work Who would have thought that street vendors
                        would be using QR codes just a few years ago?. As a
                        doctor who has relocated to a new state, I understand
                        the challenges of finding the right specialist or
                        establishing oneself in a new location. Your project
                        bridges this gap by providing accessibility for both
                        patients and doctors, making it easier for us to connect
                        with each other. This is especially important for
                        practitioners like me who are new to an area and want to
                        establish themselves. Your team's work is commendable,
                        and I believe it will have a significant impact on the
                        healthcare industry."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      Dr Jomyir Jini{" "}
                      <div className="subtitle">
                        Dept of Oral and Maxillofacial Pathology Govt{" "}
                        <div className="subtitle">
                          Dental College and Hospital, Kadapa Andhra Pradesh
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial">
                    <div className="testimonial-photo">
                      <div className="photo-background"></div>
                      <div className="photo-author"></div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        "During my posting days at Unity Life Line Hospital, I
                        witnessed firsthand the chaos and confusion that often
                        plagued patients in the queues. Many were unsure about
                        their diagnosis, didn't know what to do, or whom to
                        consult with. Your project has the potential to
                        alleviate this issue by reducing wait times and helping
                        patients choose the right doctor.Furthermore, your
                        disease prediction feature is particularly impressive.
                        It can significantly accelerate the diagnosis process,
                        enabling patients to connect with the right specialists
                        quickly. As a healthcare professional student , I can
                        attest that managing a large volume of patients in the
                        diagnostic department is a significant challenge. Your
                        project addresses this pain point, making it a
                        much-needed innovation in today's healthcare landscape.
                        I wish you the best of luck with your project."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      Anny Samchung{" "}
                      <div className="subtitle">
                        Anuradha Homeopathy Medical Collage and Hospital,
                        Hegganahali Sunkadakate Bengaluru
                      </div>
                    </div>
                  </div>
                  <div className="testimonial">
                    <div className="testimonial-photo">
                      <div className="photo-background"></div>
                      <div className="photo-author"></div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        "Well after seeing the demo of CureConnect, it was
                        interesting . As a doctor who often sees patients
                        struggling to navigate the healthcare system, the
                        concept of a one-stop shop for symptom prediction and
                        doctor recommendations is refreshing. In the demo, I was
                        particularly impressed with how the symptom prediction
                        engine narrowed down possibilities based on user input.
                        While, of course, real-world use with a wider range of
                        conditions will be the true test, the accuracy for the
                        common illnesses they showcased was promising. This
                        could be a game-changer for folks in remote areas who
                        might not have easy access to specialists, or even for
                        busy patients here in the city who are unsure where to
                        start. I'm eager to see how CureConnect performs in a
                        clinical setting and how it connects patients with the
                        right doctors based on their specific needs. This is a
                        project with a lot of potential, and I commend you
                        for your efforts."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      Rito Riba{" "}
                      <div className="subtitle">BDS, KGMU Lucknow</div>
                    </div>
                  </div>
                  <div className="testimonial">
                    <div className="testimonial-photo">
                      <div className="photo-background"></div>
                      <div className="photo-author"></div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        "As a registered nurse from Arunachal Pradesh working in
                        the ICU at GNRC hospital, I've observed that patients
                        and their families often struggle to navigate the
                        hospital system, particularly when trying to find the
                        right department for their diagnosis. I believe that an
                        app or project that streamlines this process would be
                        incredibly beneficial, saving time and reducing our
                        workload and frustration for everyone involved."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      Jumpi Kamduk{" "}
                      <div className="subtitle">
                        Nurse, GNRC Hospital Guwahati, Assam
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonials-arrows">
                  <div className="arrow arrow-left">
                    <label htmlFor="input-testimonial1"></label>
                    <label htmlFor="input-testimonial2"></label>
                    <label htmlFor="input-testimonial3"></label>
                    <label htmlFor="input-testimonial4"></label>
                    <label htmlFor="input-testimonial5"></label>
                    <span></span>
                  </div>
                  <div className="arrow arrow-right">
                    <label htmlFor="input-testimonial1"></label>
                    <label htmlFor="input-testimonial2"></label>
                    <label htmlFor="input-testimonial3"></label>
                    <label htmlFor="input-testimonial4"></label>
                    <label htmlFor="input-testimonial5"></label>
                    <span></span>
                  </div>
                </div>
                <div className="testimonials-bullets">
                  <label htmlFor="input-testimonial1">
                    <div className="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                  <label htmlFor="input-testimonial2">
                    <div className="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                  <label htmlFor="input-testimonial3">
                    <div className="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                  <label htmlFor="input-testimonial4">
                    <div className="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                  <label htmlFor="input-testimonial5">
                    <div className="bullet">
                      <div>
                        <span></span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default HomePage;
