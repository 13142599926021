import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { useDoctorAuth } from "../../context/docauth";
import { toast } from "react-toastify";
import "../../styles/DoctorHomePage.css";

const DoctorHomePage = () => {
  const [DoctorAuth, setDoctorAuth] = useDoctorAuth();
  const [appointments, setAppointments] = useState([]);
  const doctorId = DoctorAuth?.doctor?._id;

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const res = await axios.get(
        `/api/v1/fetch/booked/doctorAppointment/${doctorId}`
      );

      if (res.data.success) {
        setAppointments(res.data.appointments);
      } else {
        toast.info(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while fetching appointments.");
      console.error("Error fetching appointments:", error);
    }
  };

  function convertToIST(dateTimeString) {
    const utcDateTime = new Date(dateTimeString);
    const istDateTime = new Date(
      utcDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDateTime.toLocaleString();
  }

  const handleStatusUpdate = async (slotId, status) => {
    try {
      const res = await axios.get(
        `/api/v1/fetch/updateStatus/${slotId}/${status}`
      );

      if (res.data.success) {
        toast.success(res.data.message);
        window.location.reload();
      } else {
        toast.info(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong while fetching appointments.");
      console.error("Error fetching appointments:", error);
    }
  };

  return (
    <Layout title="Doctor Homepage">
      <div className="doctor-home-page-container">
        <div className="row mb-4">
          <div className="col-md-12">
            <h1 className="text-uppercase font-weight-bold text-black mb-4">
              Doctor Appointments
            </h1>
            <hr className="my-4" /> {/* Horizontal bar */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="doctor-panel">
              <div className="table-responsive">
                <table className="doctor-table">
                  <thead>
                    <tr>
                      <th>Start Date and Time</th>
                      <th>End Date and Time</th>
                      <th>Patient Name</th>
                      <th>Patient Gender</th>
                      <th>Patient Age</th>
                      <th>Location</th>
                      <th>Status</th>
                      <th>Action</th> {/* New column for action */}
                    </tr>
                  </thead>
                  <tbody>
                    {appointments.length === 0 ? (
                      <tr>
                        <td colSpan="8">No appointments found</td>
                      </tr>
                    ) : (
                      appointments.map((appointment) => (
                        <tr key={appointment._id}>
                          <td>{convertToIST(appointment.dateTime)}</td>
                          <td>{convertToIST(appointment.endDateTime)}</td>
                          <td>{appointment.patient.name}</td>
                          <td>{appointment.patient.gender}</td>
                          <td>{appointment.patient.age}</td>
                          <td>{appointment.patient.location}</td>
                          <td>
                            {appointment.status === "pending" ? (
                              <span style={{ color: "orange" }}>Pending</span>
                            ) : (
                              <span style={{ color: "green" }}>Confirmed</span>
                            )}
                          </td>
                          <td>
                            {/* Dropdown to select new status */}
                            <div className="transparent-dropdown">
                              <select
                                value={appointment.status}
                                onChange={(e) =>
                                  handleStatusUpdate(
                                    appointment._id,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="pending">Pending</option>
                                <option value="confirmed">Confirmed</option>
                                <option value="cancelled">Cancelled</option>
                              </select>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DoctorHomePage;
